import type { TComplainGuid } from "../types/TComplainGuid";
import type { LayoutTypes } from "@/assets/ts/enums/detail-with-payment/LayoutTypes";

export class ComplainInfo {
	constructor(
		private applicationGuid: TComplainGuid,
		private applicationType: LayoutTypes,
	) {}

	get getApplicationGuid(): TComplainGuid {
		return this.applicationGuid;
	}

	get getApplicationType(): LayoutTypes {
		return this.applicationType;
	}
}
